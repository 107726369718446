import { Obb2, KrMath, Matrix4, Vector3, Vec3Y, Vec3Z, Vec3X, Vector2, Matrix3 } from "math-ts";
import { IterUtils, Yield } from "engine-utils-ts";
import { BorderJson, LayoutGeoData, LayoutJson, RoadDataInput, ShadingDataInput, SubstationDataInput, TransformerDataInput } from "src/civil/LayoutParser";
import { XMLParser } from "fast-xml-parser";
import { ProjectionInfo } from "bim-ts";
import { DXFNode, DxfPropertyType } from "./DxfNode";
import { dxfEntityType, DxfFileManager } from "./DxfFileManager";
import { BlockTypeFlag, InsertUnits, LengthUnits } from "./DxfEnums";
import { DXFNodeFactory } from "./DxfFileFactory";
import { emit } from "process";

const arbitraryAxis: (v:Vector3) => boolean = (v) => Math.abs(v.x) < 1/64 && Math.abs(v.y) < 1/64;
const isVectorUnitZ: (v:Vector3) => boolean = (v) => v.x===0 && v.y===0 && v.z===1;
const sanitizeNumber = (value: number): number => Number.isFinite(value) ? value : 0;

export class DxfNodeDataExtractor {
    static getDrawingUnits(manager: DxfFileManager) {
        const insUnitsVariable = this.getVariable(manager, "$INSUNITS");
        const lenUnitsVariable = this.getVariable(manager, "$LUNITS");
        if (!insUnitsVariable || !lenUnitsVariable) {
            throw new Error(
                "Invalid DXF file: missing INSUNITS or LUNITS variable"
            );
        }

        const insunits = insUnitsVariable.properties.get(70) as DxfPropertyType;
        const scaleFactor = getScaleFactor(+insunits);

        const lenUnits = +(lenUnitsVariable.properties.get(
            70
        ) as DxfPropertyType) as LengthUnits;

        if (!scaleFactor || !lenUnits) {
            throw new Error("Invalid INSUNITS or LUNITS value");
        }

        return scaleFactor;
    }

    static getVariable(
        manager: DxfFileManager,
        name: string
    ): DXFNode | undefined {
        const header = manager.getSection("HEADER");
        for (const variable of header.children) {
            if (variable.type === name) {
                return variable;
            }
        }
        return undefined;
    }

    static getEntities(
        manager: DxfFileManager,
        ...entityTypes: dxfEntityType[]
    ) {
        const entities = manager.getSection("ENTITIES").children;
        if (!entityTypes) {
            return entities;
        }

        const selectedEntities: DXFNode[] = [];

        for (let i = 0; i < entities.length; i++) {
            const entity = entities[i];
            for (const entityType of entityTypes) {
                if (entity.type === entityType) {
                    selectedEntities.push(entity);
                }
            }
        }

        return selectedEntities;
    }

    static getNodeName(node: DXFNode): string | undefined {
        const name = node.properties.get(2);
        if (name === undefined || Array.isArray(name)) {
            return;
        }

        return name as string;
    }
    static getOwnerHandle(node: DXFNode): string | undefined {
        return node.properties.get(330) as string | undefined;
    }
    static getHandle(node: DXFNode): string {
        return node.properties.get(5) as string;
    }
    static getLayer(node: DXFNode): string {
        const layer = node.properties.get(8) as string | undefined;
        if (layer === undefined) {
            throw new Error("Node has no layer data!");
        }
        return layer;
    }
    static getVectorDataFromNode(
        node: DXFNode,
        codeX: number,
        codeY: number,
        codeZ?: number
    ): Vector3 {
        const x = +(node.properties.get(codeX) as DxfPropertyType);
        const y = +(node.properties.get(codeY) as DxfPropertyType);
        let z = 0;
        if (codeZ !== undefined) {
            z = +(node.properties.get(codeZ) as DxfPropertyType);
        }

        if (isNaN(x) || isNaN(y) || isNaN(z)) {
            throw new Error("Entity has no coordinates!");
        }
        return new Vector3(x, y, z);
    }

    static getNodePosition(node: DXFNode): Vector3 {
        return this.getVectorDataFromNode(node, 10, 20, 30);
    }

    static getPolylineVertices(
        node: DXFNode,
        getOnlyStraightSegments: boolean = false
    ): Vector3[] {
        const coordinates: Vector3[] = [];
        const props = node.properties.entries();
        let currentBulge = 0;
        const currentCoords: number[] = [];

        for (const property of props) {
            if (property.code === 42) {
                currentBulge = Number.parseFloat(property.value.toString());
                continue;
            }

            if (property.code === 10 || property.code === 20) {
                const value = property.value.toString();
                currentCoords.push(Number.parseFloat(value));

                if (currentCoords.length !== 2) {
                    continue;
                }

                if (currentBulge === 0) {
                    coordinates.push(new Vector3(...currentCoords));
                } else {
                    if (getOnlyStraightSegments) {
                        coordinates.pop();
                        currentCoords.length = 0;
                        continue;
                    }

                    const sPt = coordinates.pop();
                    if (!sPt) {
                        coordinates.push(new Vector3(...currentCoords));
                        currentCoords.length = 0;
                        continue;
                    }
                    const ePt = new Vector3(...currentCoords);
                    const arcData = getArcData(sPt, ePt, currentBulge);
                    const points = divideArc(arcData);
                    if (currentBulge < 0) {
                        points.reverse();
                    }
                    IterUtils.extendArray(coordinates, points);

                    currentBulge = 0;
                }
                currentCoords.length = 0;
            }
        }

        const extrusionMatrix =
            DxfNodeDataExtractor.getExtrusionDirectionMatrix(node);
        for (const coordinate of coordinates) {
            coordinate.applyMatrix4(extrusionMatrix);
        }

        return coordinates;
    }

    static getLineCoordinates(node: DXFNode) {
        if (node.type !== "LINE") {
            throw new Error(`Entity is not a line!`);
        }

        const startPoint = this.getVectorDataFromNode(node, 10, 20);
        const endPoint = this.getVectorDataFromNode(node, 11, 21);

        const coordinates: Vector3[] = [startPoint, endPoint];
        return coordinates;
    }

    static getArcPoints(arcEntity: DXFNode): Vector3[] {
        if (arcEntity.type !== "ARC") {
            throw new Error(`Entity is not an arc!`);
        }

        const center = DxfNodeDataExtractor.getVectorDataFromNode(
            arcEntity,
            10,
            20,
            30
        );
        const radius: number = Number.parseFloat(
            arcEntity.properties.get(40) as string
        );
        const startAngleDeg = Number.parseFloat(
            arcEntity.properties.get(50) as string
        );
        const endAngleDeg = Number.parseFloat(
            arcEntity.properties.get(51) as string
        );
        if (isNaN(radius) || isNaN(startAngleDeg) || isNaN(endAngleDeg)) {
            throw new Error(`Arc entity data is incomplete!`);
        }

        let startAngle: number = KrMath.degToRad(startAngleDeg);
        let endAngle: number = KrMath.degToRad(endAngleDeg);

        const pts = divideArc({ center, radius, startAngle, endAngle });

        return pts;
    }

    static get3DPolylineCoordinates(
        node: DXFNode,
    ): Vector3[] {
        if (node.type !== "POLYLINE") {
            throw new Error(`Entity is not 3d Polyline!`);
        }
        const coordinates: Vector3[] = [];
        const handle = node.properties.get(5) as string;

        for (const ent of node.children) {
            if (ent.type === "VERTEX") {
                const ownerHandle = this.getOwnerHandle(ent);
                if (ownerHandle === handle) {
                    const xs = Number.parseFloat(
                        ent.properties.get(10) as string
                    );
                    const ys = Number.parseFloat(
                        ent.properties.get(20) as string
                    );
                    const zs = Number.parseFloat(
                        ent.properties.get(30) as string
                    );
                    coordinates.push(new Vector3(xs, ys, zs));
                }
            }
        }

        return coordinates;
    }

    static getNodeRotation(node: DXFNode) {
        let r = +(node.properties.get(50) as DxfPropertyType);

        if (isNaN(r)) {
            r = 0;
        }
        return r;
    }

    static isEntityMirrored(
        blockRef: DXFNode,
        direction: "horizontaly" | "verticaly"
    ): boolean {
        if (direction === "horizontaly") {
            const scaleX = +(blockRef.properties.get(41) as DxfPropertyType);
            return !isNaN(scaleX) && scaleX < 0;
        } else {
            const scaleY = +(blockRef.properties.get(42) as DxfPropertyType);
            return !isNaN(scaleY) && scaleY < 0;
        }
    }

    static getExtrusionDirectionMatrix(node: DXFNode): Matrix4 {
        const extrusionDirection =
            DxfNodeDataExtractor.getNodeExtrusionDirection(node);

        //transform local insertion point to world coordinate
        const localCS = getLocalCS(extrusionDirection);

        const basisMatrix = new Matrix4().makeBasis(
            localCS.xAxis,
            localCS.yAxis,
            localCS.zAxis
        );

        return basisMatrix;
    }

    static getInsertMatrix(
        blockRef: DXFNode,
        scale?: number
    ): Matrix4 | undefined {
        if (blockRef.type !== "INSERT") {
            return;
        }

        const insertPoint = this.getNodePosition(blockRef);
        const resultMatrix = new Matrix4();

        const blockRotationDegrees = sanitizeNumber(
            this.getNodeRotation(blockRef)
        );
        let blockRotationRadians = KrMath.degToRad(blockRotationDegrees);
        const rotationMatrix = new Matrix4().makeRotationZ(
            blockRotationRadians
        );
        resultMatrix.premultiply(rotationMatrix);

        if (this.isEntityMirrored(blockRef, "verticaly")) {
            const verticalMirror = new Matrix4().set(
                1,
                0,
                0,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                1
            );

            resultMatrix.premultiply(verticalMirror);
        }

        if (this.isEntityMirrored(blockRef, "horizontaly")) {
            const horizontalMirror = new Matrix4().set(
                -1,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                1
            );

            resultMatrix.premultiply(horizontalMirror);
        }

        const blockMatrix = new Matrix4();
        const position = scale
            ? insertPoint.multiplyScalar(scale)
            : insertPoint;

        blockMatrix.setPositionV(position);
        resultMatrix.premultiply(blockMatrix);

        const basisMatrix = this.getExtrusionDirectionMatrix(blockRef);

        resultMatrix.premultiply(basisMatrix);

        return resultMatrix;
    }

    static getBlockDefinition(
        manager: DxfFileManager,
        insert: DXFNode | string
    ) {
        let name: string | undefined;
        if (typeof insert === "string") {
            name = insert;
        } else {
            name = this.getNodeName(insert);
        }

        const blocks = manager.getSection("BLOCKS").children;
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            const blockName = this.getNodeName(block);
            if (blockName === name) {
                return block;
            }
        }

        throw new Error(`There is no block ${name}!`);
    }
    static getBlockRecord(
        manager: DxfFileManager,
        insert: DXFNode | string
    ): DXFNode {
        let name: string | undefined;
        if (typeof insert === "string") {
            name = insert;
        } else {
            name = this.getNodeName(insert);
        }

        const blockRecs = manager.getTable("BLOCK_RECORD").children;

        for (let i = 0; i < blockRecs.length; i++) {
            const blockRec = blockRecs[i];
            const blockName = this.getNodeName(blockRec);
            if (blockName === name) {
                return blockRec;
            }
        }

        throw new Error(`There is no block ${name}!`);
    }

    static getBlockUnits(manager: DxfFileManager, node: DXFNode): number {
        if (node.type !== "INSERT") {
            return 1;
        }

        const name = this.getNodeName(node);

        //get scale of the block
        for (const blockRecord of manager.getTable("BLOCK_RECORD").children) {
            const brName = this.getNodeName(blockRecord);

            if (brName === name) {
                const insertUnits = +(blockRecord.properties.get(
                    70
                ) as DxfPropertyType);
                if (isNaN(insertUnits)) {
                    return insertUnits;
                }
                const insertScaleFactor = getScaleFactor(insertUnits);
                if (insertScaleFactor === null) {
                    throw new Error(
                        "Unable to retrieve the block's unit scale. Please check the settings of your drawing."
                    );
                } else {
                    return insertScaleFactor;
                }
            }
        }

        throw new Error(`Can not find block record of the block ${name}`);
    }
    static getNodeExtrusionDirection(node: DXFNode): Vector3 {
        const x = +((node.properties.get(210) as DxfPropertyType) ?? 0);
        const y = +((node.properties.get(220) as DxfPropertyType) ?? 0);
        const z = +((node.properties.get(230) as DxfPropertyType) ?? 1);
        return new Vector3(x, y, z);
    }

    static getBlockScaleFactor(node: DXFNode): Vector3 {
        if (node.type === "INSERT") {
            const x = +((node.properties.get(41) as DxfPropertyType) ?? 1);
            const y = +((node.properties.get(42) as DxfPropertyType) ?? 1);
            const z = +((node.properties.get(43) as DxfPropertyType) ?? 1);

            return new Vector3(x, y, z);
        }
        return new Vector3(1, 1, 1);
    }

    static getBlockPoints(manager: DxfFileManager, node: DXFNode): Vector3[] {
        let block: DXFNode;
        if (node.type === "INSERT") {
            block = this.getBlockDefinition(manager, node);
        } else if (node.type === "BLOCK") {
            block = node;
        } else {
            throw new Error("Node should be a block node!");
        }

        const entities = block.children;
        const vertices: Vector3[] = [];

        for (const ent of entities) {
            switch (ent.type) {
                case "LWPOLYLINE":
                    const plvertices = DxfNodeDataExtractor.getPolylineVertices(
                        ent,
                        true
                    );
                    if (plvertices.length > 1) {
                        IterUtils.extendArray(vertices, plvertices);
                    }

                    break;
                case "LINE":
                    IterUtils.extendArray(
                        vertices,
                        DxfNodeDataExtractor.getLineCoordinates(ent)
                    );
                    break;
                case "POLYLINE":
                    const pl3dvertices =
                        DxfNodeDataExtractor.get3DPolylineCoordinates(ent);
                    if (pl3dvertices.length > 1) {
                        IterUtils.extendArray(vertices, pl3dvertices);
                    }
                    break;

                case "INSERT":
                    const instBlockMatrix =
                        DxfNodeDataExtractor.getInsertMatrix(ent);
                    const subBlockPoints = this.getBlockPoints(manager, ent);
                    for (const point of subBlockPoints) {
                        if (instBlockMatrix) {
                            point.applyMatrix4(instBlockMatrix);
                        }

                        //vertices.push(point);
                    }
                    break;

                default:
                    break;
            }
        }

        return vertices;
    }

    static getAttributeData(
        manager: DxfFileManager,
        node: DXFNode
    ): {
        modulesPerRow: string;
        modulesPerString: string;
        moduleModel: string;
        modulePower: string;
        manufacturer: string;
    } {
        let modulesPerString = "";
        let modulesPerRow = "";
        let modulePower = "";
        let manufacturer = "";
        let moduleModel = "";

        if (node.type !== "INSERT" || !node.children.length) {
            return {
                modulesPerRow,
                modulesPerString,
                moduleModel,
                modulePower,
                manufacturer,
            };
        }

        for (const ent of node.children) {
            if (ent.type !== "ATTRIB") {
                continue;
            }
            // Process attribute entities
            switch (ent.properties.get(2) as string) {
                case "NUMBER_OF_MODULES_PER_STRING":
                    modulesPerString = ent.properties.get(1) as string;
                    break;
                case "NUMBER_OF_MODULES_PER_ROW":
                    modulesPerRow = ent.properties.get(1) as string;
                    break;
                case "MODULE_MODEL":
                    moduleModel = ent.properties.get(1) as string;
                    break;
                case "MODULE_POWER":
                    modulePower = ent.properties.get(1) as string;
                    break;
                case "MANUFACTURER":
                    manufacturer = ent.properties.get(1) as string;
                    break;
                default:
                    break;
            }
        }

        return {
            modulesPerRow: modulesPerRow,
            modulesPerString: modulesPerString,
            moduleModel: moduleModel,
            modulePower: modulePower,
            manufacturer: manufacturer,
        };
    }

    static getGeodataNode(manager: DxfFileManager): DXFNode | undefined {
        const objects = manager.getSection("OBJECTS").children;
        for (const object of objects) {
            if (object.type === "GEODATA") {
                return object;
            }
        }
        console.log("Geodata is not found!");
        return undefined;
    }

    static getXmlStringFromGeodata(node: DXFNode, code: number): string {
        if (node.type !== "GEODATA") {
            throw new Error(`Entity ${node.type} is not a geodata!`);
        }
        let nodeXmlData = "";
        const rawData = node.properties.get(code) as
            | string
            | string[]
            | undefined;
        if (rawData === undefined) {
            console.log(`geodata has no xml string at code ${code}!`);
        } else if (typeof rawData === "string") {
            nodeXmlData = rawData.replaceAll("^J", "\n");
        } else {
            nodeXmlData = rawData.join("").replaceAll("^J", "\n");
        }

        return nodeXmlData;
    }

    static getGeodata(manager: DxfFileManager): LayoutGeoData | undefined {
        const geodata = this.getGeodataNode(manager);
        if (geodata === undefined) {
            return undefined;
        }

        function getNodeUnitsScale(node: any) {
            let scale = 1;
            const nodeUnitsRaw: string = node["@_uom"];
            if (nodeUnitsRaw !== undefined) {
                const nodeUnits = nodeUnitsRaw.toLowerCase();
                if (nodeUnits === "foot") {
                    scale = 0.3048;
                } else if (nodeUnits === "inch") {
                    scale = 0.0254;
                }
            }
            return scale;
        }

        const projectionOrigin: Vector3 = this.getVectorDataFromNode(
            geodata,
            10,
            20,
            30
        );

        const horizontalScaleFactor =
            Number.parseFloat(geodata.properties.get(40) as string) ?? 1;
        const verticalScaleFactor =
            Number.parseFloat(geodata.properties.get(41) as string) ?? 1;
        const scaleVector = new Vector3(
            horizontalScaleFactor,
            horizontalScaleFactor,
            verticalScaleFactor
        );

        projectionOrigin.multiply(scaleVector);

        let xmlString = this.getXmlStringFromGeodata(geodata, 303);
        const xmlEnding = this.getXmlStringFromGeodata(geodata, 301);
        if (xmlEnding) {
            xmlString += xmlEnding;
        }

        const geopointXmlString = this.getXmlStringFromGeodata(geodata, 302);
        let geopoint: LayoutGeoData | undefined = undefined;
        const geoParameters = new Map<string, string>();
        let lat: number | undefined = undefined;
        let lon: number | undefined = undefined;
        let method: string | undefined = undefined;

        if (xmlString) {
            const options = {
                ignoreAttributes: false,
                attributeNamePrefix: "@_",
            };

            const parser = new XMLParser(options);
            const jObj = parser.parse(xmlString);

            const projCS = jObj.Dictionary?.ProjectedCoordinateSystem;

            if (projCS !== undefined) {
                method = projCS.Conversion?.Projection?.OperationMethodId;

                const projName = projCS["@_id"];
                if (projName !== undefined) {
                    geoParameters.set("ProjectionId", projName);
                }

                const parameters =
                    projCS.Conversion?.Projection?.ParameterValue;
                method = projCS.Conversion?.Projection?.OperationMethodId;

                if (parameters !== undefined) {
                    for (const param of parameters) {
                        const key: string = param.OperationParameterId;
                        if (key === null) {
                            continue;
                        }

                        let value: number, scale: number;
                        if (param.Value) {
                            value = parseFloat(param.Value["#text"]);
                            scale = getNodeUnitsScale(param.Value);
                        } else {
                            value = param.IntegerValue;
                            scale = 1;
                        }

                        if (!Number.isNaN(value)) {
                            const scaledValue = value * scale;
                            geoParameters.set(key, scaledValue.toString());
                        }
                    }
                }
            }

            const ellipsoid = jObj.Dictionary?.Ellipsoid;

            if (ellipsoid) {
                const ellipsoidName: string = ellipsoid.Name;
                geoParameters.set("EllipsoidName", ellipsoidName);

                const semiMajorAxis = ellipsoid.SemiMajorAxis;
                if (semiMajorAxis !== undefined) {
                    const scale = getNodeUnitsScale(semiMajorAxis);
                    const value: number = parseFloat(semiMajorAxis["#text"]);
                    if (Number.isFinite(value)) {
                        const scaledValue = value * scale;
                        geoParameters.set(
                            "SemiMajorAxis",
                            scaledValue.toString()
                        );
                    }
                }

                const semiMinorAxis =
                    ellipsoid.SecondDefiningParameter?.SemiMinorAxis;
                if (semiMinorAxis !== null) {
                    const scale = getNodeUnitsScale(semiMinorAxis);
                    const value = parseFloat(semiMinorAxis["#text"]);
                    if (Number.isFinite(value)) {
                        const scaledValue = value * scale;
                        geoParameters.set(
                            "SemiMinorAxis",
                            scaledValue.toString()
                        );
                    }
                }
            }

            const geoteticDatum = jObj.Dictionary?.GeodeticDatum;

            if (geoteticDatum !== undefined) {
                const geoDatumId = geoteticDatum["@_id"];
                if (geoDatumId !== undefined) {
                    geoParameters.set("GeoteticDatumId", geoDatumId);
                }
                const primeMeridianId = geoteticDatum.PrimeMeridianId;
                if (primeMeridianId) {
                    geoParameters.set("PrimeMeridianId", primeMeridianId);
                }
            }
        }

        if (geopointXmlString) {
            const parser = new XMLParser();
            const geoPointObj = parser.parse(geopointXmlString);

            const coords: string = geoPointObj["georss:point"];
            if (coords !== undefined) {
                const rawCoordData = coords.split(" ");
                if (rawCoordData.length === 2) {
                    const rawLat = parseFloat(rawCoordData[0]);
                    const rawLon = parseFloat(rawCoordData[1]);
                    if (Number.isFinite(rawLat) && Number.isFinite(rawLon)) {
                        lat = rawLat;
                        lon = rawLon;
                    } else {
                        console.error(
                            "Can't parse geodata coordinates from dxf file!"
                        );
                    }
                } else {
                    console.error(
                        "Geodata coordinates in dxf file is incorrect!"
                    );
                }
            } else {
                console.error("Can't parse coordinates data from dxf file!");
            }
        } else {
            let latVal = geodata.properties.get(21) as string | undefined;
            let lonVal = geodata.properties.get(11) as string | undefined;

            if (!latVal || !lonVal) {
                latVal = this.getVariable(manager, "$LATITUDE")?.properties.get(
                    40
                ) as string | undefined;
                lonVal = this.getVariable(
                    manager,
                    "$LONGITUDE"
                )?.properties.get(40) as string | undefined;
            }

            if (!latVal || !lonVal) {
                console.info(
                    "Could't get the geocoordinates from dxf file variables"
                );
                return undefined;
            }
            lat = Number.parseFloat(latVal);
            lon = Number.parseFloat(lonVal);
        }
        if (lat !== undefined && lon !== undefined) {
            const geodataInfo = new ProjectionInfo(method, geoParameters);
            geopoint = {
                ReferencePoint: { Latitude: lat, Longitude: lon, Altitude: 0 },
                ReferencePointOrigin: projectionOrigin,
                ProjectionInfo: geodataInfo,
            };
            return geopoint;
        } else {
            console.info("Could't get the geocoordinates from dxf file");
            return undefined;
        }
    }
}


export class DxfModelAdapter {
    private dxfManager: DxfFileManager;
    private drawingScale: number;
    constructor(dxfRoot: DXFNode) {
        this.dxfManager = new DxfFileManager(dxfRoot);
        this.drawingScale = DxfNodeDataExtractor.getDrawingUnits(
            this.dxfManager
        );
    }

    *getLayoutModel():Generator<Yield, LayoutJson, unknown> {
        const equipment = yield* this.getEqupmentData();
        const roads = yield* this.getRoads();
        const borders = yield* this.getBorders();

        const geodata = DxfNodeDataExtractor.getGeodata(this.dxfManager);

        const layout: LayoutJson = {
            Contour3D: borders,
            Roads: roads,
            Trackers: equipment.trackers,
            Transformers: equipment.transformers,
            Substations:equipment.substations,
            GeoData: geodata,
            Surfaces: [],
        };

        return layout;
    }


    private *getEqupmentData():Generator<Yield, {
        trackers: ShadingDataInput[];
        transformers: TransformerDataInput[];
        substations: SubstationDataInput[];
    }, unknown> {
        const trackers: ShadingDataInput[] = [];
        const transformers: TransformerDataInput[] = [];
        const substations: SubstationDataInput[] = [];
        const inserts = DxfNodeDataExtractor.getEntities(
            this.dxfManager,
            "INSERT",
            "LWPOLYLINE"
        );

        for (const insert of inserts) {

            const blockMatrix = DxfNodeDataExtractor.getInsertMatrix(
                insert,
                this.drawingScale
            );

            if (isNodeEntityTracker(insert) || isNodeFixedTilt(insert)) {
                //Case if standalone traker not in block
                const standaloneTrackerData = this.getTrackerData(insert);
                if (standaloneTrackerData !== undefined) {
                    trackers.push(standaloneTrackerData);
                    continue;
                }
                yield Yield.Asap;
            } else if (isNodeAnonBlock(this.dxfManager, insert)) {
                //Case if array of trackers not in block

                const arrayData = this.getTrackerArrayData(insert);
                for (const data of arrayData) {
                    trackers.push(data);
                    continue;
                }
                yield Yield.Asap;
            } else if (isNodeBlockOfTrackers(insert)) {
                const blockName = DxfNodeDataExtractor.getNodeName(insert);
                if(!blockName){
                    continue;
                }
                const blockNumber = getBlockNumber(blockName);
                const blockReference = DxfNodeDataExtractor.getBlockDefinition(
                    this.dxfManager,
                    insert
                );
                const blockId = DxfNodeDataExtractor.getHandle(insert);

                for (const insertBlock of blockReference.children) {
                    if (insertBlock.type !== "INSERT") {
                        continue;
                    }

                    if (isNodeSkid(insertBlock)) {
                        const skidData = this.getSkidData(
                            insertBlock,
                            blockId,
                            blockNumber,
                            blockMatrix
                        );
                        if (skidData !== undefined) {
                            transformers.push(skidData);
                        }
                    } else if (isNodeAnonBlock(this.dxfManager, insertBlock)) {
                        const arrayData = this.getTrackerArrayData(
                            insertBlock,
                            blockId,
                            blockMatrix
                        );
                        for (const data of arrayData) {
                            trackers.push(data);
                        }
                    } else if (
                        isNodeEntityTracker(insertBlock) ||
                        isNodeFixedTilt(insertBlock)
                    ) {
                        const blockTrackerData = this.getTrackerData(
                            insertBlock,
                            blockId,
                            blockMatrix
                        );
                        if (blockTrackerData !== undefined) {
                            trackers.push(blockTrackerData);
                        }
                    }
                }
                yield Yield.Asap;
            } else if (isNodeEntitySubstation(insert)) {
                const substation = this.getGeometryData(insert);
                if (substation) {
                    const substationData: SubstationDataInput = {
                        Name: substation.name,
                        Origin: substation.origin.asArray(),
                        Rotation: substation.rotationRad,
                    };
                    substations.push(substationData);
                }
            }
        }

        return { trackers, transformers, substations};
    }

    private getSkidData(
        blockRef: DXFNode,
        transformerId: string,
        blockNumber: number,
        matrix?: Matrix4
    ): TransformerDataInput | undefined {
        const geomDataSkid = this.getGeometryData(blockRef, matrix);
        if (geomDataSkid === null) {
            return;
        }

        const projectedSkidPosition = [
            geomDataSkid.origin.x,
            geomDataSkid.origin.y,
            0,
        ];

        //transformer rotation is set to 0 in order to support wireing
        //const skidRotation = getRotationAroundZAxisInRadians(skidMatrix);
        const skidRotation = 0;

        const skidData: TransformerDataInput = {
            Name: geomDataSkid.name,
            Origin: projectedSkidPosition,
            Id: transformerId,
            Rotation: skidRotation,
            BlockNumber: blockNumber,
        };

        return skidData;
    }

    private getTrackerData(
        node: DXFNode,
        transformerId?: string,
        matrix?: Matrix4
    ) {

        const geomData = this.getGeometryData(node, matrix);

        if (geomData === null) {
            return;
        }

        geomData.name = parseTrackerName(geomData.name);


        const attributeData = DxfNodeDataExtractor.getAttributeData(
            this.dxfManager,
            node
        );

        const trackerData: ShadingDataInput = {
            Name: geomData.name,
            Origin: geomData.origin.asArray(),
            Rotation: geomData.rotationRad,
            TransformerId: transformerId ?? "",
            NumberOfModulesPerString: parseInt(attributeData.modulesPerString),
            NumberOfModulesPerRow: parseInt(attributeData.modulesPerRow),
            Manufacturer: attributeData.manufacturer,
            ModuleModel: attributeData.moduleModel,
            ModulePower: attributeData.modulePower,
            Length: geomData.length,
            Width: geomData.width,
        };

        return trackerData;
    }

    private getGeometryData(
        node: DXFNode,
        transform?: Matrix4,
        scaleFactor?: number
    ): {
        name: string;
        width: number;
        length: number;
        rotationRad: number;
        origin: Vector3;
    } | null {

        const drawingScaleFactor =  scaleFactor ?? this.drawingScale;
        
        const layerName = node.properties.get(8) as string | undefined;


        let blockScaleFactor:Vector3 = DxfNodeDataExtractor.getBlockScaleFactor(node);
         
        let entityPoints: Vector2[] = [];
        let extrusionDirection: Vector3 = new Vector3(0,0,1);
        let name:string = "";

        if (node.type === "INSERT") {
            name = DxfNodeDataExtractor.getNodeName(node) ?? "";

            extrusionDirection =
                DxfNodeDataExtractor.getNodeExtrusionDirection(node);

            entityPoints = DxfNodeDataExtractor.getBlockPoints(
                this.dxfManager,
                node
            ).map((p) => new Vector2(p.x, p.y));


        }else if(node.type === "LWPOLYLINE"){
            entityPoints = DxfNodeDataExtractor.getPolylineVertices(node).map((p) => new Vector2(p.x, p.y));
        }

        if (entityPoints.length < 2) {
            console.error(`Faled to parse tracker ${name}`);
            return null;
        }
        
        const blockBB = Obb2.fromPoints({ points: entityPoints, rotationsRoundToDeg:0.0001 });

        if (blockBB === null) {
            console.error(`Faled to get tracker ${name} bounding box`);
            return null;
        }
        const midPt = new Vector3(...blockBB.center().clone().asArray())
        

        blockScaleFactor = new Vector3(Math.abs(blockScaleFactor.x), Math.abs(blockScaleFactor.y), Math.abs(blockScaleFactor.z));
        midPt.multiply(blockScaleFactor);
        midPt.multiplyScalar(drawingScaleFactor);

        let width = blockBB.width(); 
        let length = blockBB.height();

        width*= blockScaleFactor.x;
        width*= drawingScaleFactor;
        
        length*= blockScaleFactor.y;
        length*= drawingScaleFactor;
        

        const localCS = getLocalCS(extrusionDirection);

        const blockRotationDegrees = sanitizeNumber(
            DxfNodeDataExtractor.getNodeRotation(node)
        );
        const localCSRotation = Vector3.angleBetweenVectorsOnXY(
            localCS.xAxis,
            Vec3X
        );
        const blockRotationRad =
            KrMath.degToRad(blockRotationDegrees) + localCSRotation;

        let rotationInsideBlock = blockBB.rotationAngle();

        
        if (isNodeFixedTilt(node)) {
            if (rotationInsideBlock === 0) {
                [width, length] = [length, width];
            } else {
                rotationInsideBlock -= Math.PI / 2;
            }
            if(!name){
                name = `${layerName}_${(width/drawingScaleFactor).toFixed(2)}_${(length/drawingScaleFactor).toFixed(2)}`;
            }
        
        }else if(!name){
            name = `${layerName}_${(width/drawingScaleFactor).toFixed(2)}_${(length/drawingScaleFactor).toFixed(2)}`;
        }

        let rotation = blockRotationRad + rotationInsideBlock;

        if (transform !== undefined) {
            rotation += getRotationAroundZAxisInRadians(transform);
        }

        const trackerBlockMatrix = DxfNodeDataExtractor.getInsertMatrix(
            node,
            drawingScaleFactor
        );
        if(trackerBlockMatrix){
            midPt.applyMatrix4(trackerBlockMatrix);
        }
        
        if (transform !== undefined) {
            midPt.applyMatrix4(transform);
        }

        return {
            name: name,
            width: width,
            length: length,
            rotationRad: rotation,
            origin: midPt,
        };
    }

    private getTrackerArrayData(
        node: DXFNode,
        transformerId?: string,
        blockMatrix?: Matrix4
    ): ShadingDataInput[] {
        const arrayData: ShadingDataInput[] = [];
        const insertEntityName = DxfNodeDataExtractor.getNodeName(node);
        const blockDef = DxfNodeDataExtractor.getBlockDefinition(
            this.dxfManager,
            node
        );
        const arrMatrix = DxfNodeDataExtractor.getInsertMatrix(
            node,
            this.drawingScale
        ) ?? new Matrix4;

        for (const arrayEntity of blockDef.children) {
            if (arrayEntity.type !== "INSERT") {
                continue;
            }
            const arrayInsertName =
                DxfNodeDataExtractor.getNodeName(arrayEntity);
            const arrayInsertDef = DxfNodeDataExtractor.getBlockDefinition(
                this.dxfManager,
                arrayEntity
            );

            const arrayInsertMatrix = DxfNodeDataExtractor.getInsertMatrix(
                arrayEntity,
                this.drawingScale
            );

            let resultMatrix = arrMatrix.clone();
            if(arrayInsertMatrix){
                resultMatrix = arrMatrix.clone().multiply(arrayInsertMatrix);
            }
            
            if (blockMatrix !== undefined) {
                resultMatrix = blockMatrix.clone().multiply(resultMatrix);
            }

            for (const arrayElement of arrayInsertDef.children) {
                if (arrayElement.type !== "INSERT") {
                    continue;
                }

                if (
                    isNodeEntityTracker(arrayElement) ||
                    isNodeFixedTilt(arrayElement)
                ) {
                    const trackerData: ShadingDataInput | undefined =
                        this.getTrackerData(
                            arrayElement,
                            transformerId,
                            resultMatrix
                        );
                    if (trackerData !== undefined) {
                        arrayData.push(trackerData);
                    }
                }
            }
        }
        return arrayData;
    }

    private *getRoads():Generator<Yield, RoadDataInput[], unknown> {
        const entities = this.dxfManager.getSection("ENTITIES").children;
        const roadLayerCheck = (str: string): boolean =>
            /^(?:__|pvfarm_)?road(?:axis)?_/i.test(str);

        const layoutRoads: RoadDataInput[] = [];
        for (const entity of entities) {
            const layer = DxfNodeDataExtractor.getLayer(entity);
            if (roadLayerCheck(layer)) {
                if (
                    entity.type === "LWPOLYLINE" ||
                    entity.type === "LINE" ||
                    entity.type === "ARC"
                ) {

                    //TODO:extrusion direction
                    const roadData = entityToRoadData(entity);
                    if(!roadData.Points.length){
                        console.log(`Failed to parse points of the road ${entity.type}, handle ${entity.properties.get(5)}`);
                        continue;
                    }

                    //Scale road to meters
                    for (let i = 0; i < roadData.Points.length; i++) {
                        roadData.Points[i].multiplyScalar(this.drawingScale);
                    }
                    layoutRoads.push(roadData);
                    yield Yield.Asap;
                }
            }
        }

        return layoutRoads;
    }

    *getBorders():Generator<Yield, BorderJson[], unknown> {
        const layoutBorders: BorderJson[] = [];
        const borderLayerCheck = (str: string): boolean =>
            /^(?:__|pvfarm_)?(?:border|boundary)_?.*/i.test(str);
        const entities = this.dxfManager.getSection("ENTITIES").children;
        for (const entity of entities) {
            const layer = DxfNodeDataExtractor.getLayer(entity);
            if (entity.type === "LWPOLYLINE" && borderLayerCheck(layer)) {
                const borderPoints = entityToBorders(entity);
            
                //Scale border to meters
                for (let i = 0; i < borderPoints.length; i++) {
                    borderPoints[i].multiplyScalar(this.drawingScale);
                }
                layoutBorders.push({name:layer, points:borderPoints});
                yield Yield.Asap;
            }
        }

        return layoutBorders;
    }
}

function angleRelativeToXAxis(p1: Vector3, p2: Vector3): number {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;

    let angle = Math.atan2(dy, dx);

    if (angle < 0) {
        angle += 2 * Math.PI;
    }

    return angle;
}

function polar(origin: Vector3, angle: number, distance: number): Vector3 {
    const x = origin.x + distance * Math.cos(angle);
    const y = origin.y + distance * Math.sin(angle);
    const z = origin.z;  
    return new Vector3(x, y, z);
}

function getArcData(
    p1: Vector3,
    p2: Vector3,
    bulge: number
): {
    center: Vector3;
    radius: number;
    startAngle: number;
    endAngle: number;
} {

    const theta = 4.0 * Math.atan(Math.abs(bulge));
    const c = p1.distanceTo(p2)/ 2;

    const radius = c / Math.sin(theta / 2);

    const a = Math.sqrt(Math.pow(radius, 2) - Math.pow(c, 2));
    const angle = angleRelativeToXAxis(p1, p2);
    
    const midp = polar(p1, angle, c);
    
    const center = bulge > 0
        ? polar(midp, angle + Math.PI / 2, a)
        : polar(midp, angle - Math.PI / 2, a);
  

    let startAngle = Math.atan2(p1.y - center.y, p1.x - center.x);
    let endAngle = Math.atan2(p2.y - center.y, p2.x - center.x);

    [startAngle, endAngle] = bulge<0 ? [endAngle, startAngle] : [startAngle, endAngle]

    return {
        center,
        radius,
        startAngle,
        endAngle,
    };
}



function divideArc(data:{center:Vector3, radius:number, startAngle:number, endAngle:number}){
    const calculateArcPoint = (
        center: Vector3,
        radius: number,
        angle: number
    ): Vector3 => {
        return new Vector3(
            center.x + radius * Math.cos(angle),
            center.y + radius * Math.sin(angle),
            center.z
        );
    };

    const points:Vector3[] = [];

    const startAngle = data.startAngle % (2 * Math.PI);
    let endAngle = data.endAngle % (2 * Math.PI);

    
    if (startAngle > endAngle) {
        endAngle += 2 * Math.PI; 
    }
    const sectorAngle = endAngle - startAngle;

    const segmentsCount = Math.round(Math.max((data.radius * sectorAngle)/10, 1));
    const tStep = sectorAngle / segmentsCount;

    for (let t = startAngle; t < endAngle + tStep/2; t += tStep) {
        const pt = calculateArcPoint(data.center, data.radius, t);
        points.push(pt);
    }

    return points;

}

function isNodeEntitySubstation(entity: DXFNode): boolean {
    const entName = DxfNodeDataExtractor.getNodeName(entity)?.toLowerCase();
    return (
        entName !== undefined &&
        (entName.startsWith("substation") ||
            entName.startsWith("pvfarm_substation"))
    );
}

function isNodeEntityTracker(entity: DXFNode): boolean {
    if (entity.type === "INSERT") {
        const entName = DxfNodeDataExtractor.getNodeName(entity)?.toLowerCase();
        return (
            entName !== undefined &&
            (entName.startsWith("tracker") ||
                entName.startsWith("pvfarm_tracker") ||
                entName.startsWith("any-tracker") ||
                entName.startsWith("pvfarm_any-tracker"))
        );
    } else if (entity.type === "LWPOLYLINE") {
        const layerName = entity.properties.get(8) as string | undefined;
        if (
            layerName === undefined ||
            !layerName.startsWith("pvfarm_tracker")
        ) {
            return false;
        }

        const vertisies = DxfNodeDataExtractor.getPolylineVertices(
            entity,
            true
        );
        const closed = entity.properties.get(70) as number | undefined;
        return closed !== 1 && (vertisies.length === 4 || vertisies.length === 5);
    }

    return false;
}


function isNodeFixedTilt(entity: DXFNode): boolean {
    if (entity.type === "INSERT") {
        const entName = DxfNodeDataExtractor.getNodeName(entity)?.toLowerCase();
        return (
            entName !== undefined &&
            (entName.startsWith("fixed-tilt") ||
                entName.startsWith("pvfarm_fixed-tilt"))
        );
    } else if (entity.type === "LWPOLYLINE") {
        const layerName = entity.properties.get(8) as string | undefined;
        if (
            layerName === undefined ||
            !layerName.startsWith("pvfarm_fixed-tilt")
        ) {
            return false;
        }

        const vertisies = DxfNodeDataExtractor.getPolylineVertices(
            entity,
            true
        );
        const closed = entity.properties.get(70) as number | undefined;
        return closed !== 1 && vertisies.length === 4;
    }

    return false;
}

function isNodeAnonBlock(manager:DxfFileManager, entity: DXFNode): boolean {

    const blockName = DxfNodeDataExtractor.getNodeName(entity);
    if(blockName===undefined){
        return false;
    }
    const block = manager.getBlock(blockName);
    const blockTypeFlag = +(block?.properties.get(70) as DxfPropertyType);
    if(isNaN(blockTypeFlag)){
        return false;
    }

    return blockTypeFlag === BlockTypeFlag.AnonymousBlock;
}

function isNodeSkid(entity: DXFNode): boolean {
    const entName = DxfNodeDataExtractor.getNodeName(entity)?.toLowerCase();
    return entName!==undefined && (entName.startsWith("skid_") || entName.startsWith("pvfarm_skid_")) ;
}
function isNodeBlockOfTrackers(entity: DXFNode): boolean {
    const entName = DxfNodeDataExtractor.getNodeName(entity)?.toLowerCase();
    return entName!==undefined && (entName.startsWith("block_") || entName.startsWith("pvfarm_block_")) ;
}

function entityToRoadData(entity: DXFNode): RoadDataInput {
    const points: Vector3[] = [];

    if (entity.type === 'LWPOLYLINE') {
        const vertices = DxfNodeDataExtractor.getPolylineVertices(entity)
        IterUtils.extendArray(points, vertices);
        
    } else if (entity.type === 'LINE') {
        const vertices = DxfNodeDataExtractor.getLineCoordinates(entity);
        IterUtils.extendArray(points, vertices);

    } else if (entity.type === 'ARC') {
        const vertices = DxfNodeDataExtractor.getArcPoints(entity);
        IterUtils.extendArray(points, vertices);
    }

    const layer = DxfNodeDataExtractor.getLayer(entity);
    const width = getRoadWidth(layer)

    return { Points: points, Width: width };
}

function entityToBorders(entity: DXFNode): Vector3[] {

    if (entity.type !== "LWPOLYLINE") {
        throw new Error(`Provided entity of type ${entity.type} is not a polyline!`);
    }
    const points = DxfNodeDataExtractor.getPolylineVertices(entity);

    return points;
}


function getLocalCS(N: Vector3): {
    xAxis: Vector3;
    yAxis: Vector3;
    zAxis: Vector3;
} {
    const zAxis = N.clone().normalize();

    let xAxis:Vector3;

    if (arbitraryAxis(zAxis)) {
        xAxis = Vector3.crossVectors(Vec3Y, N).normalize();
    } else {
        xAxis = Vector3.crossVectors(Vec3Z, N).normalize();
    }

    const yAxis = Vector3.crossVectors(zAxis, xAxis).normalize();

    return { xAxis: xAxis, yAxis: yAxis, zAxis: zAxis };
}

function getBlockNumber(blockName: string): number {

    const blockNumberData: string = blockName.split('_').pop() || '';
    const value = parseInt(blockNumberData);
    return value;
}

function getRoadWidth(layerName: string): number {
    const sf: number = 0.3048;
    const widthData: string = layerName.toLowerCase().split('_').pop() || '';

    const pattern: RegExp = /(\d+([,.]\d+)?)(ft|m)/;
    const match: RegExpMatchArray | null = widthData.match(pattern);

    if (match && match.length > 0) {
        const matchValue: string = match[0];

        // Use regular expression to separate numeric and unit parts
        const subMatch: RegExpMatchArray | null = matchValue.match(pattern);

        if (subMatch && subMatch.length > 0) {
            const numericPart: string = subMatch[1];
            const unit: string = subMatch[3];

            const numericPartWithDot: string = numericPart.replace(",", ".");
            const value: number = parseFloat(numericPartWithDot);

            if (!isNaN(value)) {
                if (unit === "ft") {
                    // Convert to meters
                    return value * sf;
                } else if (unit === "m") {
                    return value;
                }
            }
        } else {
            return 1;
        }
    }

    return 1;
}

function getScaleFactor(units: InsertUnits): number|null{
    let value = 1;
    switch (units) {
        case 0: // Unitless (generic units)
            value *= 0.3048; // By default if unitless than feet to meters,
            break;
        case 1: // Inches to Meters
            value *= 0.0254;
            break;
        case 2: // Feet to Meters
            value *= 0.3048;
            break;
        case 3: // Miles to Meters
            value *= 1609.34;
            break;
        case 4: // Millimeters to Meters
            value *= 0.001;
        case 5: // Centimeters to Meters
            value *= 0.01;
            break;
        case 6: // Meters (no conversion needed)
            break;
        case 7: // Kilometers to Meters
            value *= 1000;
            break;
        case 21:
            value *= 1200/3937
            break;
        default:
            return null;
    }

    return value;
}


function getRotationAroundZAxisInRadians(matrix: Matrix4): number {

    const elements = matrix.toArray(); 
    const angleInRadians = Math.atan2(elements[5], elements[4]) - Math.PI / 2;

    return angleInRadians;
}

function parseTrackerName(name:string): string {
    const parts = name.split("_");
    const filteredParts = parts.filter(part => !(part.startsWith("(") && part.endsWith(")")));
    return filteredParts.join("_");
}
