import type { Catalog, AssetId, BimProperty} from "bim-ts";
import { type Bim, type IdBimScene, type SceneInstancePatch, PropsGroupsRegistry, PropsFieldArrayOf, type PropsGroupField, producePropsPatch, EditTrackerPilesContext, PropertyBase, CustomPropsRegistry, basicPropertyValueToString, getPropertyAllBasicValues, ModuleProps, PvModuleMandatoryProps, AnyTrackerProps } from "bim-ts";
import { PropsGroupBase } from "bim-ts";
import { LegacyLogger, Success } from "engine-utils-ts";
import type { PUI_ConfigBasedBuilderParams, PUI_GroupNode, PUI_Node} from "ui-bindings";
import { PUI_Builder, PUI_BuilderCallbacks, tryAddPuiPropertyNode, type PUI_GroupNodeArgs, type PUI_NodeHeaderSelectorDescription } from "ui-bindings";
import { FieldsMergeState, type GroupsCommonFieldsMerger } from "./CommonFieldsMerger";
import { PropsFieldOneOf, type PropsGroupComplexDefaults } from "bim-ts";
import { VirtualPropertyBase } from 'bim-ts';
import type { AsyncPropsUiRetriver } from './AsyncPropsUiRetriver';



export function buildPropsUi(args: {
    props: GroupsCommonFieldsMerger<PropsGroupBase>,
    sharedTypeIdentifier: string | undefined,
    ids: IdBimScene[],
    bim: Bim,
    catalog: Catalog,
    asyncPropsUiRetriver: AsyncPropsUiRetriver,
    mappingParams: PUI_ConfigBasedBuilderParams,
}): PUI_GroupNode {
    const builderCallbacks = new PUI_BuilderCallbacks();
    builderCallbacks.addAfterEveryNodeCallback(trackerModuleSelectorUiBuilderCallback(args.ids, args.bim, args.catalog));
    builderCallbacks.addAfterEveryNodeCallback(anyTrackerPilesEditor(args.ids, args.bim));
    const puiBuilder = new PUI_Builder({
        rootName: '',
        callbacks: builderCallbacks,
    });

    const basicPropsFormatters = args.bim.instances.basicPropsView.formatters;

    args.props.buildPui<PropsGroupComplexDefaults<any> | undefined>(
        puiBuilder,
        (groupClass, fullPath) => {
            let groupDefaultsContext: PropsGroupComplexDefaults<any> | undefined = undefined;
            if (groupClass.prototype instanceof PropsGroupBase) {
                groupDefaultsContext = PropsGroupsRegistry.getComplexDefaultsFor(groupClass as any);
            }
            const name = (fullPath.at(-1) ?? '').toString();
            const groupNodeParams: PUI_GroupNodeArgs = { name, sortChildren: true };
            return { groupNodeArgs: groupNodeParams, context: groupDefaultsContext };
        },
        (puiBuilder, merger, fullPath, parentsContext) => {

            let propertyValue = merger.result();

            const name = fullPath.at(-1) ?? '';
            const path = fullPath.slice(0, fullPath.length - 1);

            let headerSelector: PUI_NodeHeaderSelectorDescription | undefined = undefined;

            if (parentsContext.at(-1)) {
                const parentGroupDefaults = parentsContext.at(-1)!;
                const nodeDefaults = parentGroupDefaults[name];
                if (nodeDefaults instanceof PropsFieldOneOf) {
                    // headerSelector = createSelectorHeaderForUnionProperty(nodeDefaults, fullPath, args.ids, args.bim);
                } else if (nodeDefaults instanceof PropsFieldArrayOf) {

                } else if (nodeDefaults !== undefined) {
                    console.error(`at ${fullPath.join(':')} unexpected node defaults type`, nodeDefaults);
                }
            }

            const additionalNodeValue = merger.state() === FieldsMergeState.Different ? {
                value: merger.nodeValue(),
                readonly: merger.isReadonly()
            } : {};

            let outerContext = undefined;
            // TODO: remove this
            if (propertyValue instanceof VirtualPropertyBase) {
                propertyValue = args.asyncPropsUiRetriver.getValueOf(propertyValue, fullPath);
                console.warn('value of virtual property', fullPath, propertyValue);
                if (propertyValue instanceof Success) {
                    propertyValue = propertyValue.value;
                }
                outerContext = propertyValue;
                additionalNodeValue.readonly = true;
            } else {
                outerContext = parentsContext;
            }


            let { handled } = tryAddPuiPropertyNode({
                puiBuilder,
                name,
                path,
                outerContext,
                sourceValue: propertyValue,
                propsMappingParams: args.mappingParams,
                additionalNodeParams: { inHeaderSelector: headerSelector, ...additionalNodeValue },
                onChange: (newValue, fullPath, uiNode) => {
                    patchPropertiesOfAt(args.ids, fullPath, newValue, args.bim);
                },
            });

            if (!handled && propertyValue instanceof PropertyBase) {
                const basicView = CustomPropsRegistry.tryGetBasicTypesViewForClass(propertyValue.constructor as any);
                if (basicView) {
                    handled = true;
                    const values = getPropertyAllBasicValues(basicPropsFormatters, basicView, propertyValue);

                    if (values instanceof Map) {
                        puiBuilder.inGroup({
                            name: name.toString(),
                            sortChildren: true,
                        }, () => {
                            for (const [key, value] of values) {
                                puiBuilder.addStringProp({
                                    name: key,
                                    readonly: true,
                                    value: basicPropertyValueToString(value, basicPropsFormatters),
                                    onChange: () => {},
                                });
                            }
                        })
                    } else if (values) {
                        puiBuilder.addStringProp({
                            name: name.toString(),
                            readonly: true,
                            value: basicPropertyValueToString(values, basicPropsFormatters),
                            onChange: () => {},
                        });
                    }
                }
            }

            if (!handled && typeof propertyValue === 'object' && propertyValue !== null) {
                handled = true;
                puiBuilder.addCustomProp({
                    name: name.toString(),
                    type_ident: propertyValue.constructor.name,
                    value: propertyValue,
                    context: outerContext,
                    readonly: true,
                    onChange: (newValue, uiNode) => {
                        console.error('not implemented custom property change callback', newValue, uiNode)
                    },
                });
            }

            if (!handled && propertyValue !== null) {
                console.error(`at ${fullPath.join(':')} property mapping is not implemented for`, propertyValue);
            }
        }
    );
    const rootGroup = puiBuilder.finish();
    return rootGroup.children.get('') as PUI_GroupNode ?? rootGroup;
}

// function createSelectorHeaderForUnionProperty(
//     nodeDefaults: PropsFieldOneOf,
//     fullPath: (string|number)[],
//     ids: IdBimScene[],
//     bim: Bim,
// ): PUI_NodeHeaderSelectorDescription | undefined {
//     if (!nodeDefaults.flags.userSelectable) {
//         return undefined;
//     }
//     const selectorOptions: string[] = nodeDefaults.allowedTypesDefaultValues.map((type) => {
//         if (type === null) {
//             return 'None';
//         }
//         if (Array.isArray(type)) {
//             const typesList = [...new Set(type.map((t) => t.constructor.name))].join('|');
//             return `${typesList}[]`;
//         }
//         return type.constructor.name;
//     });
//     return {
//         options: selectorOptions,
//         onSelected: (_selectedOption, index) => {
//             const newFieldValue = nodeDefaults.allowedTypesDefaultValues[index];
//             patchPropertiesOfAt(ids, fullPath, newFieldValue, bim);
//         }
//     };
// }


function patchPropertiesOfAt(ids: IdBimScene[], atPath: (string | number)[], newFieldValue: PropsGroupField, bim: Bim) {
    if (ids.length === 0) {
        console.warn('no instances selected to patch', atPath.join('|'));
        return;
    }

    const instancesPatches: [IdBimScene, SceneInstancePatch][] = [];
    for (const id of ids) {
        const instance = bim.instances.peekById(id);
        if (!instance) {
            continue;
        }
        const propsPatch = producePropsPatch(instance.props, (props) => {
            let current: any = props;
            for (let i = 0; i < atPath.length - 1; i++) {
                const key = atPath[i];
                const val: any = current[key];
                if (!(val instanceof PropsGroupBase) && !(val instanceof Array)) {
                    LegacyLogger.warn('unable to patch props at', atPath.join('|'), 'because obj is not PropsGroup or Array', key, 'in', current);
                    return;
                }
                current = val;
            }
            const key = atPath.at(-1)!;
            if (!(key in current)) {
                LegacyLogger.error('unable to patch props at', atPath.join('|'), 'because of missing key', key, 'in', current);
                return;
            }
            current[key] = newFieldValue;

        });
        if (!propsPatch) {
            continue;
        }
        if (instance.props !== propsPatch) {
            instancesPatches.push([id, { props: propsPatch }]);
        }
    }
    bim.instances.applyPatches(instancesPatches);
}



function anyTrackerPilesEditor(
    ids: IdBimScene[],
    bim: Bim,
): (b: PUI_Builder, node: PUI_Node) => void  {

    return (builder: PUI_Builder, node: PUI_Node) => {
        if (ids.length === 1 && node.name === 'module' && bim.instances.peekTypeIdentOf(ids[0]) === 'any-tracker') {
            builder.addCustomProp<
                EditTrackerPilesContext,
                {}
            >({
                name: "edit-tracker-piles",
                value: new EditTrackerPilesContext(true, ids),
                type_ident: "edit-tracker-piles",
                context: {},
                onChange: () => {
                    console.error("edit-tracker-piles not changed");
                },
            });
        }
    }
}

function trackerModuleSelectorUiBuilderCallback(
    ids: IdBimScene[],
    bim: Bim,
    catalog: Catalog,
): (b: PUI_Builder, node: PUI_Node) => void  {
    return (builder: PUI_Builder, node: PUI_Node) => {
        if(!(node.parent?.name === "module" && node.name === "manufacturer")) { 
            return;
        }
        
        if(ids.some(id => bim.instances.peekTypeIdentOf(id) !== "any-tracker")){
            return;
        }

        const instances = bim.instances.peekByIds(ids);
        const pvModuleValues = new Set<string>();
        for (const [_, inst] of instances) {
            const hash = bim.keyPropertiesGroupFormatter.fullKeyPropsStr(
                "pv-module",
                inst.properties,
                inst.props,
            );
            if (hash) {
                pvModuleValues.add(hash);
            }
        }
        const value:number[] = [];
        if (pvModuleValues.size === 1 && catalog) {
            for (const assetId of catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId.perId.keys()) {
                const si = catalog.assets.sceneInstancePerAsset
                    .getAssetAsSceneInstance(assetId)
                if (!si || si.type_identifier !== "pv-module") {
                    continue;
                }
                const hash = catalog.keyPropertiesGroupFormatters.fullKeyPropsStr(
                    si.type_identifier,
                    si.properties,
                    si.props,
                );

                if (hash && pvModuleValues.has(hash)) {
                    value.push(assetId);
                    break;
                }
            }
        }

        builder.addCustomProp<
            AssetId[],
            {
                maxCount: number;
                types: string[];
            }
        >({
            name: "pv-module",
            value: value,
            typeSortKeyOverride: 0,
            type_ident: "assets-selector",
            context: {
                maxCount: 1,
                types: ["pv-module"],
            },
            onChange: ([v]) => {
                if (!v) {
                    console.error("pv-module not changed", v);
                    return;
                }
                const pvModule = catalog.assets.sceneInstancePerAsset
                    .getAssetAsSceneInstance(v)
                if (!pvModule) {
                    console.error("pv-module not changed");
                    return;
                }
                const trackerProps = bim.instances.peekById(ids[0])?.propsAs(AnyTrackerProps);
                if(!trackerProps) {
                    return;
                }
                const pvProps: Partial<Record<keyof typeof PvModuleMandatoryProps, BimProperty>> = {};
                
                for (const key in PvModuleMandatoryProps) {
                    const prop = PvModuleMandatoryProps[key as keyof typeof PvModuleMandatoryProps];
                    if(!prop) {
                        continue;
                    }
                    pvProps[key as keyof typeof PvModuleMandatoryProps] = pvModule.properties.get(prop._mergedPath);
                }

                const module = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(ModuleProps, {
                    width: pvProps.width ? { value: pvProps.width.asNumber(), unit: pvProps.width.unit } : undefined,
                    length: pvProps.length ? { value: pvProps.length.asNumber(), unit: pvProps.length.unit } : undefined,
                    
                    manufacturer: pvProps.manufacturer ? { value: pvProps.manufacturer.asText() } : undefined,
                    model: pvProps.model ? { value: pvProps.model.asText() } : undefined,
                    
                    technology: pvProps.technology ? { value: pvProps.technology.asText() } : undefined,

                    mounting: {
                        standard: pvProps.standard ? { value: pvProps.standard.asText() } : undefined,
                    },

                    current: pvProps.current ? { value: pvProps.current.asNumber(), unit: pvProps.current.unit } : undefined,
                    voltage: pvProps.voltage ? { value: pvProps.voltage.asNumber(), unit: pvProps.voltage.unit } : undefined,
                    max_system_voltage: pvProps.max_system_voltage ? { value: pvProps.max_system_voltage.asNumber(), unit: pvProps.max_system_voltage.unit } : undefined,
                    maximum_power: pvProps.maximum_power ? { value: pvProps.maximum_power.asNumber(), unit: pvProps.maximum_power.unit } : undefined,
                    open_circuit_voltage: pvProps.open_circuit_voltage ? { value: pvProps.open_circuit_voltage.asNumber(), unit: pvProps.open_circuit_voltage.unit } : undefined,
                    short_circuit_current: pvProps.short_circuit_current ? { value: pvProps.short_circuit_current.asNumber(), unit: pvProps.short_circuit_current.unit } : undefined,

                    temp_coeff_current: pvProps.temp_coeff_current ? { value: pvProps.temp_coeff_current.asNumber(), unit: pvProps.temp_coeff_current.unit } : undefined,
                    temp_coeff_power: pvProps.temp_coeff_power ? { value: pvProps.temp_coeff_power.asNumber(), unit: pvProps.temp_coeff_power.unit } : undefined,
                    temp_coeff_voltage: pvProps.temp_coeff_voltage ? { value: pvProps.temp_coeff_voltage.asNumber(), unit: pvProps.temp_coeff_voltage.unit } : undefined,
                    
                    bifaciality_factor: pvProps.bifaciality_factor ? { value: pvProps.bifaciality_factor.asNumber(), unit: pvProps.bifaciality_factor.unit } : undefined,
                    degradation: {
                        power_degradation: pvProps.power_degradation ? { value: pvProps.power_degradation.asNumber(), unit: pvProps.power_degradation.unit } : undefined,
                        first_year_power_degradation: pvProps.first_year_power_degradation ? { value: pvProps.first_year_power_degradation.asNumber(), unit: pvProps.first_year_power_degradation.unit } : undefined,
                    },
                });
        
                const patch = producePropsPatch(trackerProps, (props) => {
                    props.module = module;
                });

                if (!patch) {
                    return;
                }
                bim.instances.applyPatchTo(
                    {
                        props: patch,
                    },
                    ids,
                    {
                        allowPropsShapeHookToResetExistingPropsToDefaultValues: true,
                    }
                );
            },
        });
        
    }
}